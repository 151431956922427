.geosuggest {
  margin: 2em 0;
  font-size: 1rem;
  display: inline-block;
}

.geosuggest__input {
  width: 300px;
  line-height: 2rem;
  padding: 0 1em;
}

.geosuggest__input:focus {
  border-color: #267dc0;
  outline: none;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
  position: absolute;
  background: white;
  line-height: 2rem;
  list-style: none;
  border: 2px solid #267dc0;
  z-index: 1000;
  margin-top: -1px;
  cursor: pointer;
  padding: 0;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border: none;
}

.geosuggest__item {
  padding: 0 1em;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #267dc0;
  color: white;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}
