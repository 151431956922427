body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.geosuggest {
  margin: 2em 0;
  font-size: 1rem;
  display: inline-block;
}

.geosuggest__input {
  width: 300px;
  line-height: 2rem;
  padding: 0 1em;
}

.geosuggest__input:focus {
  border-color: #267dc0;
  outline: none;
  box-shadow: 0 0 0 transparent;
}

.geosuggest__suggests {
  position: absolute;
  background: white;
  line-height: 2rem;
  list-style: none;
  border: 2px solid #267dc0;
  z-index: 1000;
  margin-top: -1px;
  cursor: pointer;
  padding: 0;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border: none;
}

.geosuggest__item {
  padding: 0 1em;
}

.geosuggest__item:hover,
.geosuggest__item:focus {
  background: #267dc0;
  color: white;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.spinner-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background: #ffffff8a;
  z-index: 99999;
}

.spinner {
  margin: 100px auto;
  width: 50px;
  height: 65px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: #2196f3;
  height: 100%;
  width: 6px;
  display: inline-block;
  margin-left: 2px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.container {
  text-align: center;
}

